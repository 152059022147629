

document.addEventListener('DOMContentLoaded', function() {
    let currentYear = new Date().getFullYear();
    document.getElementById('year').textContent = currentYear;
});

let x = document.querySelector('#menuMobile');

//Fechar menu quando clicar
window.addEventListener('mouseup', function(e) {

    if (e.target.id != 'menuMobile') {
        x.classList.remove("active");
    }
});

$("#phoneInst").keyup(function(){
    try {
        $("#phoneInst").unmask();
    } catch (e) {}

    var tamanho = $("#phoneInst").val().length;
 
    if(tamanho < 11){
        $("#phoneInst").mask("(00) 0000-00000");
    } else {
        $("#phoneInst").mask("(00) 00000-0000");
    }

    // ajustando foco
    var elem = this;
    setTimeout(function(){
        // mudo a posição do seletor
        elem.selectionStart = elem.selectionEnd = 10000;
    }, 0);
    // reaplico o valor para mudar o foco
    var currentValue = $(this).val();
    $(this).val('');
    $(this).val(currentValue);
});

$("#tax_document_number").keyup(function(){
    try {
        $("#tax_document_number").unmask();
    } catch (e) {}

    var tamanho = $("#tax_document_number").val().length;

    if(tamanho < 12){
        $("#tax_document_number").mask("999.999.999-999");
    } else {
        $("#tax_document_number").mask("99.999.999/9999-99");
    }

    // ajustando foco
    var elem = this;
    setTimeout(function(){
        // mudo a posição do seletor
        elem.selectionStart = elem.selectionEnd = 10000;
    }, 0);
    // reaplico o valor para mudar o foco
    var currentValue = $(this).val();
    $(this).val('');
    $(this).val(currentValue);
});

(() => {
    let menu = document.querySelector("#menuMobile");

    function openMenu(){
        menu.classList.add('active');
    }

    function closeMenu(){
        menu.classList.remove('active');
    }

    window.header = {
        openMenu: openMenu,
        closeMenu: closeMenu,
    }
})();

(() => {
    function selectPlan(ID){
        let plans = document.querySelectorAll('.plans_form_left_single');

        //Retirar active de todos os planos
        plans.forEach(plan => {
            plan.classList.remove('active');
        });

        //Pegar e mostrar o ID que foi clicado
        let plan = document.querySelector('#' + ID);

        plan.classList.add('active');

        //Switch para preencher o hidden
        let planHidden = '';
        let price = '';
        switch(ID){
            case 'planMEI':
                planHidden = 'mei';
                price = 'R$29,90';
                break;
            case 'planSIMPLES':
                planHidden = 'simples_nacional';
                price = 'R$54,90';
                break;
            case 'planPRES':
                planHidden = 'lucro_presumido';
                price = 'R$84,90';
                break;
            case 'planREAL':
                planHidden = 'lucro_real';
                price = 'Sob Consulta';
                break;
            default:
                planHidden = 'mei';
                price = 'R$29,90';
                break;
        }

        //Pegar ID do Hidden e substituir para plano selecionado e substituir preço;
        document.querySelector('#planForm').value = planHidden;
        document.querySelector('#price').innerHTML = price;
    }

    window.plans = {
        selectPlan: selectPlan,
    }
})();

(() => {

    let cards = document.querySelectorAll('.faq_body_single');

    function openFaq(faq){
       
        cards.forEach(card => {
            card.classList.remove('active');

            let cardToShow = document.getElementById(faq);

            cardToShow.classList.add('active');
        });
    }

    window.faq = {
        openFaq: openFaq,
    }
})();